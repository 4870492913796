<template>
    <div id="baseInfo" class="creat-resume-wrapper user-content flex">
        <div class="creat-resume">
            <div  class="u-resume-title flex" ref="baseInfo">
                <div class="r-title-left">创建简历</div>
<!--                <div class="r-title-right">更新时间：2020-01-17</div>-->
            </div>
            <el-form ref="userCreateFormRef" :model="userCreateForm" label-width="120px">
                <!-- 简历的基本信息    必填内容              begin -->
                <div  class="u-base-form">
                    <h3>编辑简历基本信息</h3>
                    <!-- 学历 以及薪资-->
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="学历：">
                                <template slot="label">
                                    <div style="text-align:right;">
                                        <span style="color:#ff0000;">*</span> 学历：
                                    </div>
                                </template>
                                <el-select :disabled="!isBaseInfoEdit" v-model="userCreateForm.education"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in educationList"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="应届毕业生：">
                                <template slot="label">
                                    <div style="text-align:right;">
                                        <span style="color:#ff0000;">*</span> 应届毕业生：
                                    </div>
                                </template>
                                <el-select v-model="userCreateForm.isStudent" placeholder="请选择">
                                    <el-option label="是" :value="1"></el-option>
                                    <el-option label="否" :value="0"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        
                        <el-col :span="12">
                            <el-form-item label="期望薪资：">
                                <template slot="label">
                                    <div style="text-align:right;">
                                        <span style="color:#ff0000;">*</span> 期望薪资：
                                    </div>
                                </template>
                                <el-select :disabled="!isBaseInfoEdit" v-model="userCreateForm.expectSalary"
                                           placeholder="请选择期望薪资">
                                    <el-option v-for="(item,index) in salaryList" :key="index" :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="工作年限：">
                                <template slot="label">
                                    <div style="text-align:right;">
                                        <span style="color:#ff0000;">*</span> 工作年限：
                                    </div>
                                </template>
                                <!--                                <el-input placeholder="请填写工作年限" v-model="userCreateForm.workExperience"></el-input>-->
                                <el-select :disabled="!isBaseInfoEdit" v-model="userCreateForm.workExperience"
                                           placeholder="请选择工作年限">
                                    <el-option
                                            v-for="item in workExperienceList"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 期望行业，期望职位 -->
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="职位分类：">
                                <template slot="label">
                                    <div style="text-align:right;">
                                        <span style="color:#ff0000;">*</span> 职位分类：
                                    </div>
                                </template>
                                <!--                                <el-input placeholder="请填写工作年限" v-model="userCreateForm.workExperience"></el-input>-->
                                <el-cascader
                                        ref="industrySelector"
                                        :disabled="!isBaseInfoEdit"
                                        v-model="userCreateForm.industry"
                                        :options="industryList"
                                        :props="jobProps"
                                        :clearable="true"
                                        @change="industrySelected"
                                >
                                </el-cascader>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="期望职位：">
                                <template slot="label">
                                    <div style="text-align:right;">
                                        <span style="color:#ff0000;">*</span> 期望职位：
                                    </div>
                                </template>
                                <el-input :disabled="!isBaseInfoEdit" placeholder="请填写期望岗位"
                                          v-model="userCreateForm.expectPost"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 离职状态   到岗日期 -->
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="求职状态：">
                                <template slot="label">
                                    <div style="text-align:right;">
                                        <span style="color:#ff0000;">*</span> 求职状态：
                                    </div>
                                </template>
                                <el-select :disabled="!isBaseInfoEdit" v-model="userCreateForm.nowStatus"
                                           placeholder="请选择求职状态">
                                    <el-option
                                            :key="index"
                                            :label="item.label"
                                            :value="item.value"
                                            v-for="(item,index) in workStatusList"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="第一期望工作地:">
                                <area-cascader :disabled="!isBaseInfoEdit" style="line-height: 20px;"
                                               v-model="userCreateForm.liveAddress"
                                               type="text" :level="1"
                                               :data="pcaa"></area-cascader>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="第二期望工作地:">
                                <area-cascader :disabled="!isBaseInfoEdit" style="line-height: 20px;"
                                               v-model="userCreateForm.liveAddress1"
                                               type="text" :level="1"
                                               :data="pcaa"></area-cascader>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="第三期望工作地:">
                                <area-cascader :disabled="!isBaseInfoEdit" style="line-height: 20px;"
                                               v-model="userCreateForm.liveAddress2"
                                               type="text" :level="1"
                                               :data="pcaa"></area-cascader>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 工作年限  期望工作地 -->
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="意向行业：">
                                <el-select v-model="checkindustids"  multiple  filterable placeholder="请选择">
                                    <el-option
                                    v-for="item in allindustryList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <!-- <el-col :span="12">
                            <el-form-item label="到岗日期：">
                                <el-date-picker
                                        v-model="userCreateForm.arrivalTime"
                                        type="date"
                                        placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col> -->
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <!-- 个人介绍 -->
                            <div id="myselfIntroduce" class="about-self-wrapper" ref="aboutSelf">
                                <div class="list-title">
                                    <h3>自我介绍</h3>
                                    <div class="add-about-self">
                                        <el-input
                                                :disabled="!isBaseInfoEdit"
                                                type="textarea"
                                                :autosize="{ minRows: 4}"
                                                placeholder="请输入内容"
                                                v-model="userCreateForm.selfEvaluation">
                                        </el-input>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>

                    <el-row :gutter="20" style="margin-top: 20px;">
                        <el-col :span="24">
                            <div class="creat-del flex" v-show="isBaseInfoEdit">
                                <el-button v-if="resumeId" type="primary" size="medium" @click="confirmCreateResume">更新</el-button>
                                <el-button v-else type="primary" size="medium" @click="confirmCreateResume">保存</el-button>
                                <el-button type="danger" size="medium" @click="clearResumeForm">清空</el-button>
                            </div>
                            <div class="creat-del flex" v-show="!isBaseInfoEdit">
                                <el-button type="primary" @click="isBaseInfoEdit=true">编辑</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <!-- 简历的基本信息    必填内容                 end -->
                <!-- 工作历史 -->
                <div id="workInfo" class="work-years" ref="workInfo">
                    <div class="list-title">
                        <h3>工作经历</h3>
                        <div class="list-add-item" @click="createStyleSection('work')">
                            <i class="el-icon-circle-plus-outline"></i> 添加
                        </div>
                    </div>
                    <div class="w-years-list-wrapper">
                        <div class="w-years-list">
                            <work-section v-for="(item,index) in workStyleList" :key="index" :formData.sync="item"
                                          :resumeId="resumeId"
                                          @addSection="addStyleSection"
                                          @deleteSection="deleteStyleSection"
                            ></work-section>
                        </div>
                        <div class="w-years-list-empty"></div>
                    </div>
                </div>
                <!-- 教育历史 -->
                <div id="educationInfo" class="education-list-wrapper" ref="educationInfo">
                    <div class="list-title">
                        <h3>教育经历</h3>
                        <div class="list-add-item" @click="createStyleSection('education')">
                            <i class="el-icon-circle-plus-outline"></i> 添加
                        </div>
                    </div>
                    <div class="education-list">
                        <education-section  v-for="(item,index) in educationStyleList" :key="index" :formData="item"
                                            :resumeId="resumeId"
                                            @addSection="addStyleSection"
                                            @deleteSection="deleteStyleSection"></education-section>
                    </div>
                </div>
                <!-- 工作历史 -->
                <div id="projectInfo" class="project-list-wrapper" ref="projectInfo">
                    <div class="list-title">
                        <h3>项目经历</h3>
                        <div class="list-add-item" @click="createStyleSection('project')">
                            <i class="el-icon-circle-plus-outline"></i> 添加
                        </div>
                    </div>
                    <div class="project-list">
                        <project-section  v-for="(item,index) in projectStyleList" :key="index" :formData="item"
                                          :resumeId="resumeId"
                                          @addSection="addStyleSection"
                                          @deleteSection="deleteStyleSection"
                        ></project-section>
                    </div>
                </div>
            </el-form>
        </div>
        <!-- 导航栏部分 -->
        <div class="creat-link" ref="linkList">
            <div class="link-list-wrapper">
                <div class="link-item" v-for="(item,index) in navList" :key="index"
                     :class="{'link-active': currentNavIndex===index}">
                    <span @click="returnCom(item.id,index)">{{item.title}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import areaData from 'area-data';
    import userRequest from "@/api/user";
    import workSection from "@/pages/users/resume/workSection";
    import educationSection from "@/pages/users/resume/educationSection";
    import projectSection from "@/pages/users/resume/projectSection";

    export default {
        components: {
            workSection,
            educationSection,
            projectSection
        },
        created() {
            this.getIndustry() // 获取全部的行业列表
            this.getIndustryList()
            // console.log("获取到的用户的信息",this.$route)
            
        },
        mounted() {
        },
        data() {
            return {
                resumeId:"", // 当前简历的id，如果是有的话，就是数据回显，没有就是新建
                resumeBaseInfo:null,
                pcaa: areaData.pcaa,    // 省市区的数据
                isBaseInfoEdit: true,
                industryList: [],  // 行业的列表，用户选择行业+期望岗位
                allindustryList: [],  // 行业的列表，用户选择行业+期望岗位
                industryString: "", // 选择的行业的字符串
                areaSelected: ['江西省', '广信区', '信州区'],
                jobProps: { //  级联的行业列表的配置
                    expandTrigger: 'click',
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                industryId: "", // 选择的行业的id
                currentNavIndex: 0,   // 当前导航的位置索引
                navList: [  // 导航列表
                    {id: "#baseInfo", title: "基本信息"},
                    {id: "#workInfo", title: "工作经历"},
                    {id: "#educationInfo", title: "教育经历"},
                    {id: "#projectInfo", title: "项目经历"},
                    // {id: "#myselfIntroduce", title: "自我评价"}
                ],
                //workExperienceList: ['无经验', '应届生', '1年以下', '1-2年', '2-3年', '3-5年', '5-8年', '8-10年', '10年以上'],
                workExperienceList: [  // 工作经验列表
                    {label: "无经验", value: 1},
                    {label: "应届生", value: 2},
                    {label: "1年以下", value: 3},
                    {label: "1-2年", value: 4},
                    {label: "2-3年", value: 5},
                    {label: "3-5年", value: 6},
                    {label: "5-8年", value: 7},
                    {label: "8-10年", ue: 8},
                    {label: "10年以上", value: 9}
                ],
                salaryList: [
                    {label: "1000-2000元", value: 1},
                    {label: "2000-3000元", value: 2},
                    {label: "3000-5000元", value: 3},
                    {label: "5000-8000元", value: 4},
                    {label: "8000-12000元", value: 5},
                    {label: "12000-15000元", value: 6},
                    {label: "15000-20000元", value: 7},
                    {label: "20000元以上", value: 8},
                ],
                educationList: [
                    {label: "初中及以下", value: 1},
                    {label: "高中", value: 2},
                    {label: "中专/中技", value: 3},
                    {label: "大专", value: 4},
                    {label: "本科", value: 5},
                    {label: "硕士", value: 6},
                    {label: "MBA/EMBA", value: 7},
                    {label: "博士", value: 8}
                ],
                workStatusList:[
                    {label:"我目前在职，但考虑换个新环境",value:1},
                    {label:"我目前已离职，可快速到岗",value:2},
                    {label:"观望有好的机会再考虑",value:3},
                    {label:"目前暂无跳槽打算",value:4},
                    {label:"应届毕业生",value:5}
                ],
                userCreateForm: {  // 用户创建简历的基本表单
                    education: "",   // 学历
                    expectSalary: "",        // 期望的薪资
                    industry: "",  // 选择的行业，字符串
                    expectPost: "",      // 期望的职位
                    nowStatus: "",   // 当前的状态
                    liveAddress: [],  // 字符串，使用逗号连接
                    liveAddress1: [],  // 字符串，使用逗号连接
                    liveAddress2: [],  // 字符串，使用逗号连接
                    workExperience: "",  // 工作年限
                    selfEvaluation:"",
                    isStudent:1,//是否应届毕业生
                    intentionIds:null,//意向行业
                    intentionNames:null,//意向行业名称
                },
                resumeSubmitForm: {},  // 简历基本信息提交的表单
                workStyleList: [],  // 工作经历
                educationStyleList: [], // 教育经历
                projectStyleList: [],  // 项目经历
                isStyleAction: false, //当前是否有正在编辑的经历, 添加的时候打开，保存的时候关闭
                checkindustids:[],//选择的意向行业ids
                checkindustnames:[],//选择的意向行业names
            }
        },
        methods: {
            changeIndust(){
                this.userCreateForm.intentionIds=this.checkindustids.join(",")
                this.checkindustnames=[]
                for(let item of this.allindustryList){
                    if(this.checkindustids.indexOf(item.id)!=-1){
                        this.checkindustnames.push(item.name)
                    }
                }
                this.userCreateForm.intentionNames=this.checkindustnames.join(",")
            },
            getIndustryList() {
                userRequest.getIndustryList().then((res) => {
                    console.log(res)
                    if (res.code === 200) {
                    this.allindustryList = res.data;
                    
                    }
                });
            },
            /**
             * 从后端获取行业的列表，用于确认行业
             */
            getIndustry() {
                userRequest.getAllIndustry().then((res) => {
                    console.log(res)
                    if (res.code === 200) {

                        this.industryList = res.data

                        if(this.$route.query.resumeId){
                            this.resumeId = this.$route.query.resumeId
                            this.getUserResume(this.$route.query.resumeId)
                        }
                    }
                })
            },
            getUserResume(id){ // 获取用户的简历数据用于回显，然后更新
                userRequest.getResumeInfo({resumeId: id}).then((res) => {
                    if (res.code === 200) {
                        /**
                         * 简历基本信息修改
                         * @type {{}}
                         */
                        this.resumeBaseInfo = res.data.resumeDO
                        this.industryString = this.resumeBaseInfo.industry
                        this.educationList.forEach(res=>{
                            if(res.label === this.resumeBaseInfo.education){
                                this.userCreateForm.education = res.value
                            }
                        })
                        this.salaryList.forEach(res=>{
                            if(res.label === this.resumeBaseInfo.expectSalary){
                                this.userCreateForm.expectSalary = res.value
                            }
                        })
                        let industryArr = this.resumeBaseInfo.industry.split(',')  // 行业的三个名字
                        let industryIdList = []
                        let currentNumber = 0

                        let getName = function (node) {
                            console.log(node)
                            console.log(2222)
                            if(!node || node.length===0){
                                return;
                            }
                            for(let i =0;i<node.length;i++){
                                console.log(industryArr[currentNumber])
                                console.log(node[i].name)
                                console.log(66666)
                                if(industryArr[currentNumber] === node[i].name){
                                    industryIdList.push(node[i].id)
                                    currentNumber++;
                                    getName(node[i].children)
                                }
                            }
                        }
                        // for(let item of this.industryArr){
                        //     let findobj=this.industryList.find((n)=>n.name=item)
                        //     if(findobj){
                        //         industryIdList.push(findobj.id)
                        //     }
                        // }

                        getName(this.industryList)
                        this.userCreateForm.industry = industryIdList
                        

                        this.userCreateForm.expectPost = this.resumeBaseInfo.expectPost
                        this.workStatusList.forEach(res=>{
                            if(res.label === this.resumeBaseInfo.nowStatus){
                                this.userCreateForm.nowStatus = res.value
                            }
                        })
                        this.checkindustids = this.resumeBaseInfo.intentionIds.split(',').map(Number)
                        this.userCreateForm.intentionIds = this.resumeBaseInfo.intentionIds.split(',').map(Number)
                        this.userCreateForm.intentionNames = this.resumeBaseInfo.intentionNames.split(',')
                        this.checkindustnames = this.resumeBaseInfo.intentionNames.split(',')
                        this.userCreateForm.liveAddress = this.resumeBaseInfo.liveAddress.split(',')
                        this.userCreateForm.liveAddress1 = this.resumeBaseInfo.liveAddress1.split(',')
                        this.userCreateForm.liveAddress2 = this.resumeBaseInfo.liveAddress2.split(',')
                        this.userCreateForm.isStudent = this.resumeBaseInfo.isStudent*1

                        this.workExperienceList.forEach(res=>{
                            if(res.label === this.resumeBaseInfo.workExperience){
                                this.userCreateForm.workExperience = res.value
                            }
                        })
                        this.userCreateForm.selfEvaluation = this.resumeBaseInfo.selfEvaluation

                        /**
                         * 简历的工作经历
                         */

                        this.workStyleList = res.data.resumeWorkExperienceDOS
                        this.educationStyleList = res.data.resumeEductionExperienceDO
                        this.projectStyleList = res.data.resumeProjectExperienceDO
                    }
                })
            },
            returnCom(selector, index) {
                this.currentNavIndex = index
                // 移动距离
                let top = 0;
                // 当前滚动条位置
                const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                // 若为指定距离
                if (typeof selector === 'number') {
                    top = selector - scrollTop;
                } else {
                    const anchor = document.querySelector(selector) || {offsetTop: 0};
                    top = anchor.offsetTop - scrollTop;
                }
                top = top - 100;
                window.scrollBy({top, behavior: 'smooth'});
            },
            industrySelected(data) {   // 选择行业
                let labels = this.$refs['industrySelector'].getCheckedNodes();
                this.industryString = labels[0].pathLabels.join(',');
            },
            /**
             * 个人经历填写完以后的保存
             * **/
            addStyleSection(Obj) {
                if (Obj.type === 'work') {  // 当前是工作经历的列表
                    // this.workStyleList.push(Obj.form)
                }

                this.$message.success("保存成功")
            },
            deleteStyleSection(data) { // 删除
                // let  type = data.type
                // this[type+'StyleList'].splice(data.formIndex,1)

            },
            createStyleSection(name){
                if(this[name+'StyleList'].length>4){
                    this.$message.warning("经历至多可创建五份")
                    return;
                }else{
                    this[name+'StyleList'].push({})
                }
            },
            /**
             * 确认创建简历
             */
            confirmCreateResume() {
                let map = new Map()
                map.set('education', '学历')
                map.set('expectSalary', '期望薪资')
                map.set('industry', '期望行业')
                map.set('expectPost', '期望职位')
                map.set('nowStatus', '求职状态')
                map.set('workExperience', '工作年限')
                map.set('selfEvaluation', '自我介绍')
                let isFalse = false
                for (let [key, value] of map) {
                    if (this.userCreateForm[key] === "") {
                        this.$message.warning(value + "不能为空")
                        isFalse = true;
                        return;
                    }
                }
                if (isFalse) {
                    return;
                }
                this.changeIndust()
                // console.log('获取到的表单数据', this.userCreateForm)
                let a = JSON.stringify(this.userCreateForm)
                this.resumeSubmitForm = JSON.parse(a)
                this.resumeSubmitForm.isApp=false
                this.resumeSubmitForm.industry = this.industryString
                let liveArr = this.userCreateForm.liveAddress
                this.resumeSubmitForm.liveAddress = liveArr[0] + "," + liveArr[1] + ',' + liveArr[2]
                this.resumeSubmitForm.liveAddress1=this.userCreateForm.liveAddress1.join(",")
                this.resumeSubmitForm.liveAddress2=this.userCreateForm.liveAddress2.join(",")
                // console.log("最终提交给后端的表单", this.resumeSubmitForm)
                
                if(this.resumeId){
                    this.resumeSubmitForm.id = this.resumeId
                    userRequest.updateResume(this.resumeSubmitForm).then(res=>{
                        if(res.code === 200){
                            // console.log("创建简历的返回值",res)
                            this.isBaseInfoEdit = false
                            this.$message.success("更新成功")
                        }
                    })
                }else{
                    userRequest.createResumeBase(this.resumeSubmitForm).then(res=>{
                        if(res.code === 200){
                            // console.log("创建简历的返回值",res)
                            this.isBaseInfoEdit = false
                            this.$message.success("保存成功")
                        }
                    })
                }
            },
            /**
             * 清空当前的这个表单
             */
            clearResumeForm() {
                this.$confirm('即将清空当前简历的基本信息', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // console.log("清空表单")
                    this.userCreateForm = {  // 用户创建简历的基本表单
                        education: "",   // 学历
                        expectSalary: "",        // 期望的薪资
                        industry: "",  // 选择的行业，字符串
                        expectPost: "",      // 期望的职位
                        nowStatus: "",   // 当前的状态
                        liveAddress: ["江西省", "广信区", "信州区"],  // 字符串，使用逗号连接
                        workExperience: ""  // 工作年限
                    }
                    this.$message.success("清空成功")
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消清空'
                    })
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "../../../assets/css/global.scss";

    .area-select {
        line-height: 20px;
    }

    a {
        text-decoration: none;
    }

    .el-form-item__label {
        line-height: 24px !important;
    }

    .list-title {
        position: relative;
        padding: 0 12px 10px 12px;
        font-size: 18px;
        font-weight: 400;
        border-bottom: 1px solid #f8f8f8;
        margin-bottom: 20px;

        &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 14px;
            border-radius: 10px;
            background-color: $main;
            left: 0;
            top: 2px;
        }

        .list-add-item {
            cursor: pointer;
            position: absolute;
            top: 5px;
            right: 8px;
            font-size: 14px;
            font-weight: 400;
            color: $main;
        }
    }

    .creat-del {
        justify-content: flex-end;
    }

    .creat-resume {
        width: 884px;
        margin-top: 20px;

        .u-resume-title {
            padding: 20px;
            background: #fff;
            justify-content: space-between;
            align-items: center;

            .r-title-left {
                font-size: 16px;
                font-weight: 700;
            }

            .r-title-right {
                color: #999;
            }
        }

        .u-base-form {
            box-sizing: border-box;
            width: 100%;
            padding: 20px 30px 20px 50px;
            background-color: #f8f9fb;

            h3 {
                color: #24272e;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 15px;
            }
        }

        .work-years {
            padding: 20px;
            margin-top: 20px;
            background: #fff;

            .w-years-list-wrapper {
                min-height: 70px;

                .w-years-list {
                    .w-years-item {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .education-list-wrapper {
            margin-top: 20px;
            padding: 20px;
            background: #fff;

            .education-list {
                min-height: 70px;

                .education-item {
                    margin-bottom: 20px;
                }
            }
        }

        .project-list-wrapper {
            padding: 20px;
            margin-top: 20px;
            background: #fff;
            margin-bottom: 100px;

            .project-list {
                .project-item {

                }
            }
        }

        .about-self-wrapper {
            padding: 20px;
            margin-top: 20px;
            background: #fff;

            .add-about-self {
                margin-top: 20px;
            }
        }
    }

    .creat-link {
        position: fixed;
        width: 304px;
        top: 187px;
        right: 320px;
        margin-top: 20px;
        margin-left: 5px;
        background: #fff;

        .link-list-wrapper {
            /*position: fixed;*/
            /*top: 50%;*/
            /*left: 0;*/
            width: 304px;
            background: #fff;
            box-sizing: border-box;

            .link-item {
                padding: 14px 20px;
                cursor: pointer;

                &:hover {
                    background: #f8f8f8;
                }

                &.link-active {
                    position: relative;
                    color: $main;
                    background: #f8f8f8;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 3px;
                        left: 0;
                        top: 0;
                        height: 42px;
                        background: $main;
                    }
                }
            }
        }
    }

    .base-info-btn-box {
        width: 100%;
        height: 50px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: row-reverse;

        el-button {
            margin: 0px 20px;
            background-color: #00c2b3;
        }
    }

    .save-box {
        margin: 30px auto;
        width: 400px;
        background-color: $main;
        color: #FFFFFF;
        height: 60px;
        line-height: 60px;
        text-align: center;
    }
</style>