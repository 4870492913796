<template>
    <!--
        经历片段，可以执行删除以及保存的操作，删除的时候，提出弹框
        数据内容：表单（传输数据，真实数据），display表单（用于展示）
        回显：单独一个method，把数据回显到组件里，用于浏览
        删除：请求服务器删除操作，传递父组件，删除数组
        保存：请求服务器添加操作，传递父组件，添加数组
-->

    <div class="education-item" v-if="isSectionShow">
        <el-row>
            <el-col :span="24">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学校名称：">
                            <el-input :disabled="!isEdit" placeholder="请填写学校名称" v-model="displayForm.schoolName"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="专业：">
                            <el-input placeholder="请填写专业" :disabled="!isEdit" v-model="displayForm.major"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-col>
<!--            <el-col :span="12">-->
<!--                <el-form-item label="学历：">-->
<!--                    <el-input placeholder="请选择学历"-->
<!--                              v-model="displayForm.educationLabel"></el-input>-->
<!--                </el-form-item>-->
<!--            </el-col>-->

            <el-col :span="12">
                <el-form-item label="在校时间：">
                    <div class="flex">
                        <el-date-picker
                                v-model="displayForm.startTime"
                                :disabled="!isEdit"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择日期">
                        </el-date-picker>
                        <span style="margin:0 10px">至</span>
                        <el-date-picker
                                v-model="displayForm.endTime"
                                :disabled="!isEdit"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择日期">
                        </el-date-picker>
                    </div>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <div class="justify-end flex" v-show="isEdit">
                    <el-button type="primary" plain size="small"
                               @click="saveData">确定
                    </el-button>
                    <el-button type="danger" plain size="small"
                               @click="deleteSection">删除此项
                    </el-button>
                </div>
                <div class="justify-end flex" v-show="!isEdit">
                    <el-button type="primary" size="small"
                               @click="isEdit = true">编辑
                    </el-button>
                </div>
            </el-col>
        </el-row>
    </div>

</template>

<script>
    import userRequest from "@/api/user";

    export default {
        name: "educationSection",
        created() {
            this.displayForm = this.formData
            this.displayForm.resumeId = this.resumeId
            this.displayData()
        },
        props: {  // 接收一个表单对象,对这个表单对象处理来回显数据
            formData: {  // 当前表单的数据
                type: Object
            },
            resumeId:{
                type:String,
                default:""
            }
        },
        data() {
            return {
                displayForm: {  // 回显表单

                },
                isEdit: false,
                isSectionShow:true, // 默认显示片段
                salaryList: [
                    {label: "1000-2000元", value: 1},
                    {label: "2000-3000元", value: 2},
                    {label: "3000-5000元", value: 3},
                    {label: "5000-8000元", value: 4},
                    {label: "8000-12000元", value: 5},
                    {label: "12000-15000元", value: 6},
                    {label: "15000-20000元", value: 7},
                    {label: "20000元以上", value: 8},
                ],
            }
        },
        methods: {
            displayData() {  // 数据回显
            },
            saveData() {  // 保存数据
                console.log("提交给后端的数据", this.displayForm)
                let map = new Map()
                map.set('schoolName', '学校名称')
                map.set('major', '专业名')
                // map.set('salaryId', '薪资水平')
                map.set('startTime', '开始日期')
                map.set('endTime', '结束日期')
                // map.set('dutyContent', '工作内容')
                // map.set('selfEvaluation', '自我介绍')
                let isFalse = false
                for (let [key, value] of map) {
                    if (!this.displayForm[key]) {
                        this.$message.warning(value + "不能为空")
                        isFalse = true;
                        return;
                    }
                }
                if (isFalse) {
                    return;
                }
                if(this.displayForm.id){ // 更新模式
                    userRequest.resumeUpdateEducation(this.displayForm).then(res=>{
                        this.$message.success("更新成功")
                    })
                }else{
                    userRequest.resumeAddEducation(this.displayForm).then(res=>{
                        this.$message.success("保存成功")
                    })
                }
                this.isEdit = false
            },
            deleteSection() {  // 删除当前的片段
                // this.$emit("deleteSection", this.formIndex)
                if(this.displayForm.id){  // 是后台更新的，不是自己前台新增没保存的
                    userRequest.resumeDeleteSection({id:this.displayForm.id,type:1}).then(res=>{
                        console.log("删除片段的返回值",res)
                    })
                }
                this.isSectionShow = false
                // this.$emit("deleteSection")
                this.$message.success("删除当前片段成功")
            }
        }
    }
</script>

<style lang="scss" scoped>
        .education-item{
            margin-bottom: 50px;
        }
</style>