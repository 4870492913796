<template>
    <!--
            经历片段，可以执行删除以及保存的操作，删除的时候，提出弹框
            数据内容：display表单（传输数据，真实数据)
            回显：单独一个method，把数据回显到组件里，用于浏览
            保存：请求服务器添加操作，传递父组件，添加数组
            删除：请求服务器删除操作，传递父组件，删除数组
    -->
    <!-- 工作经历片段 -->
    <div class="w-years-item" v-if="isSectionShow">
        <el-row>
            <el-col :span="12">
                <el-form-item label="公司名称：">
                    <el-input placeholder="请填写公司名称" :disabled="!isEdit" v-model="displayForm.companyName"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="职位名称：">
                    <el-input placeholder="请填写职位" :disabled="!isEdit" v-model="displayForm.position"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item label="薪资水平：">
                    <el-select v-model="displayForm.salaryId" @change="salaryChange" :disabled="!isEdit"
                               placeholder="请选择在职时薪资">
                        <el-option v-for="(item,index) in salaryList" :key="index" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="在职时间：">
                    <div class="flex">
                        <el-date-picker
                                v-model="displayForm.startTime"
                                :disabled="!isEdit"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="入职日期">
                        </el-date-picker>
                        <span style="margin:0 10px">至</span>
                        <el-date-picker
                                v-model="displayForm.endTime"
                                :disabled="!isEdit"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="离职日期">
                        </el-date-picker>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="24">
                <el-form-item label="工作描述：">
                    <el-input
                            type="textarea"
                            :disabled="!isEdit"
                            :autosize="{ minRows: 4}"
                            placeholder="请输入工作描述"
                            v-model="displayForm.dutyContent">
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <div class="justify-end flex" v-show="isEdit">
                    <el-button type="primary" plain size="small"
                               @click="saveData">确定
                    </el-button>
                    <el-button type="danger" plain size="small"
                               @click="deleteSection">删除此项
                    </el-button>
                </div>
                <div class="justify-end flex" v-show="!isEdit">
                    <el-button type="primary" size="small"
                               @click="isEdit = true">编辑
                    </el-button>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import userRequest from "@/api/user";

    export default {
        name: "workSection",
        created() {
            this.displayForm = this.formData
            this.displayForm.resumeId = this.resumeId
            this.displayData()
        },
        props: {  // 接收一个表单对象,对这个表单对象处理来回显数据
            formData: {  // 当前表单的数据
                type: Object
            },
            resumeId: {  // 简历id
                type: String,
                default: ""
            }

        },
        data() {
            return {
                isSectionShow: true, // 显示当前片段
                sectionId: '',  // 当前经历的id，用来编辑
                displayForm: {  // 回显表单

                },
                isEdit: false,  // 当前片段的状态
                salaryList: [
                    {label: "1000-2000元", value: 1},
                    {label: "2000-3000元", value: 2},
                    {label: "3000-5000元", value: 3},
                    {label: "5000-8000元", value: 4},
                    {label: "8000-12000元", value: 5},
                    {label: "12000-15000元", value: 6},
                    {label: "15000-20000元", value: 7},
                    {label: "20000元以上", value: 8},
                ],
            }
        },
        methods: {
            salaryChange(data) {
                console.log("选择的薪资", data)
                let cIndex = this.salaryList.findIndex(res=>{
                    if(res.value ===data){
                        return true
                    }
                })
                this.displayForm.salary = this.salaryList[cIndex].label
            },
            displayData() {  // 数据回显
                let salaryName = this.displayForm.salary
                let cIndex = this.salaryList.findIndex(res=>{
                    if(res.label === salaryName){
                        return true
                    }
                })
                this.displayForm.salaryId = this.salaryList[cIndex].value
            },
            saveData() {  // 保存数据
                console.table("提交给后端的数据", this.displayForm)
                let map = new Map()
                map.set('companyName', '公司名称')
                map.set('position', '职位名称')
                map.set('salaryId', '薪资水平')
                map.set('startTime', '入职日期')
                map.set('endTime', '离职日期')
                map.set('dutyContent', '工作内容')
                // map.set('selfEvaluation', '自我介绍')
                let isFalse = false
                for (let [key, value] of map) {
                    if (!this.displayForm[key]) {
                        this.$message.warning(value + "不能为空")
                        isFalse = true;
                        return;
                    }
                }
                if (isFalse) {
                    return;
                }
                if (this.displayForm.id) {  // 当前是更新模式
                    userRequest.resumeUpdateWork(this.displayForm).then(res=>{
                        console.log("更新工作经历的返回值",res)
                        this.$message.success("更新成功")
                    })
                } else {
                    userRequest.resumeAddWork(this.displayForm).then(res=>{
                        console.log("创建工作经历的返回值",res)
                        this.$message.success("保存成功")
                    })
                }
                this.$emit("addSection", {
                    type: "work",
                    form: this.displayForm
                })
                this.isEdit = false
            },
            deleteSection() {  // 删除当前的片段
                // console.log("删除的索引",this.formIndex)
                if(this.displayForm.id){  // 是后台更新的，不是自己前台新增没保存的
                    userRequest.resumeDeleteSection({id:this.displayForm.id,type:3}).then(res=>{
                        console.log("删除片段的返回值",res)
                    })
                }
                this.isSectionShow = false
                this.$emit("deleteSection")
                this.$message.success("删除当前片段成功")
            }
        }
    }
</script>

<style lang="scss" scoped>

    .work-years {
        padding: 20px;
        margin-top: 20px;
        background: #fff;

        .w-years-list-wrapper {
            min-height: 70px;

            .w-years-list {

                .w-years-item {
                    margin-bottom: 20px;

                }
            }
        }
    }

</style>